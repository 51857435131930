import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import theme from "../theme";
import { ThemeProvider } from "@mui/material/styles";

const collections = [
  {
    title: "Sweatshirts",
    img: "https://product-images-okayartist.s3.amazonaws.com/generic-images/gildan-18000-white-collection-image.jpg",
    link: "/collections/sweatshirts",
    productType: "Sweatshirt",
  },
  {
    title: "Shirts",
    img: "https://product-images-okayartist.s3.amazonaws.com/generic-images/bella-canvas-3001-athletic-heather-collection-image.jpg",
    link: "/collections/shirts",
    productType: "T-shirt",
  },
  {
    title: "Tank Tops",
    img: "https://product-images-okayartist.s3.amazonaws.com/generic-images/3480+ATHLETIC+HEATHER+2.jpg",
    link: "/collections/tanktops",
    productType: "Tank Top",
  },
  {
    title: "Hoodies",
    img: "https://product-images-okayartist.s3.amazonaws.com/generic-images/hoodie-collection-image.jpg",
    link: "/collections/hoodies",
    productType: "Hoodie",
  },
];

const CollectionList = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          pt: 3,
          backgroundColor: "background.primary",
        }}
      >
        <Typography
          variant="h2"
          gutterBottom
          sx={{ color: "text.secondary", fontWeight: "bold" }}
        >
          Collection List
        </Typography>
        <Box
          sx={{
            width: "10%",
            height: "2px",
            backgroundColor: "gray",
            mx: "auto",
            my: 2,
          }}
        ></Box>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {collections.map((collection, index) => (
            <Grid item xs={4} sm={4} md={6} key={index}>
              <Box
                component={Link}
                to={`/catalog-list/${collection.productType}`}
                sx={{
                  position: "relative",
                  textDecoration: "none",
                  overflow: "hidden",
                  borderRadius: "8px",
                  "&:hover img": {
                    transform: "scale(1.1)",
                    transition: "transform 1.5s ease-in-out",
                  },
                  "&:hover .overlay": {
                    opacity: 0.5,
                    transition: "opacity 0.3s ease-in-out",
                  },
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    overflow: "hidden", // Make sure overflow is hidden for the parent container
                    "& img": {
                      width: "100%",
                      height: "100%",
                      borderRadius: "8px",
                      display: "block",
                      objectFit: "cover",
                      transition: "transform 0.3s ease-in-out",
                    },
                  }}
                >
                  <img
                    src={collection.img}
                    alt={collection.title}
                    loading="lazy"
                  />
                  <Box
                    className="overlay"
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "black",
                      opacity: 0.3,
                      borderRadius: "8px",
                      transition: "opacity 0.3s ease-in-out",
                    }}
                  ></Box>
                  <Typography
                    variant="h5"
                    sx={{
                      // top: 0,
                      // left: 0,
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      color: "white",
                      textShadow: "2px 2px 4px rgba(0,0,0,0.6)",
                      zIndex: 1,
                      // color: "text.primary",
                      fontWeight: "bold",
                    }}
                  >
                    {collection.title}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default CollectionList;
