import React, { useState, useEffect } from "react";
import {
  ButtonGroup,
  Tooltip,
  IconButton,
  Avatar,
  useMediaQuery,
} from "@mui/material";
import {
  fetchProductImages,
  fetchProductImagesDetails,
} from "../fetchProductDetails";
import { CanvasContext } from "../CanvasContext";
import { useParams } from "react-router-dom";

const ProductColorOptions = (props) => {
  const {
    setProductColorOption,
    setProductColor,
    productColorOption,
    productColor,
  } = React.useContext(CanvasContext);
  const [colorPicker, setColorPicker] = useState("");
  const [shirtColorImg, setShirtColorImg] = useState("");
  const [activeColor, setActiveColor] = useState(productColor.title);
  const [response, setResponse] = useState({});
  const [colorOptions, setColorOptions] = useState([]);
  const [preloadedImages, setPreloadedImages] = useState({});
  const matchesXS = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  // Fetch dynamodb for product
  useEffect(() => {
    const getProductImages = async () => {
      try {
        const data = await fetchProductImagesDetails(props.productBrand);
        setResponse(data);
        setProductColorOption(data.productImages[0].img);
        setActiveColor(data.productImages[0].title);
        setProductColor(data.productImages[0]);

        // Preload images and store them in state
        // const images = data[0]?.fabricImages?.L || [];
        // const preloaded = {};

        // images.forEach((color) => {
        //   const title = color.M.title.S;
        //   const img = new Image();
        //   img.src = color.M.img.S;
        //   preloaded[title] = img.src;
        // });

        // setPreloadedImages(preloaded);
        // Preload all images
        const images = data.productImages.map((product) => {
          const img = new Image();
          img.src = product.img;
          return img;
        });
        setPreloadedImages(images); // Store preloaded images
      } catch (error) {
        console.error("Error fetching product images:", error);
      }
    };

    getProductImages();
  }, []);

  useEffect(() => {
    if (response.productImages) {
      if (productColorOption == "") {
        setProductColorOption(
          response.productImages.find((element) => element !== undefined).img
        );
        setActiveColor(
          response.productImages.find((element) => element !== undefined).title
        );
      }

      // setProductColor(
      //   response.productImages.find((element) => element !== undefined)
      // );
    }
  }, [response]);

  const handleColorClick = (color) => {
    setActiveColor(color.title);
    setShirtColorImg(color.img);
    setProductColor(
      response.productImages.find((item) => item.title === color.title)
    );
    setProductColorOption(
      response.productImages.find((item) => item.title === color.title).img
    );
    // console.log(
    //   "BREAKPOINT color= " +
    //     response.productImages.find((item) => item.title === color.title)
    // );
    // Perform any additional actions like updating the product
  };

  return (
    <>
      <ButtonGroup
        orientation={matchesXS ? "horizontal" : "vertical"}
        aria-label="horizontal button group"
        variant="text"
      >
        {response.fabricImages &&
          response.fabricImages.map((color, index) => (
            <Tooltip
              key={index}
              title={color.title}
              placement="top"
              arrow
              disableInteractive
            >
              <IconButton
                id={color.title}
                // color="success"
                onClick={() => handleColorClick(color)}
                sx={{
                  opacity: activeColor === color.title ? 0.7 : 1.0, // Change opacity based on active state
                  border:
                    activeColor === color.title ? "2px solid #87CEEB" : "none", // Green border when active
                  m: 1, // Margin around buttons for better visibility
                }}
              >
                <Avatar
                  alt={`${color.title} shirt`}
                  //   src={preloadedImages[color.M.title.S]}
                  src={color.img}
                  loading="lazy"
                  sx={{ width: 24, height: 24 }}
                />
              </IconButton>
            </Tooltip>
          ))}
      </ButtonGroup>
    </>
  );
};

export default ProductColorOptions;
