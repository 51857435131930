// components/Designs.js
import React, { Suspense } from "react";

// Create a mapping of component names to lazy-loaded components
const designComponents = {
  bootlegWithOneDog: React.lazy(() =>
    import("./CustomBootlegCloudsWithOnePhoto")
  ),
  bootlegWithCloudsDog: React.lazy(() => import("./CustomBootlegDesign")),
  thisHumanBelongsTo: React.lazy(() => import("./ThisHumanBelongsTo")),
  customCollegeLetters: React.lazy(() => import("./CustomCollegeLetters")),
  customRetro: React.lazy(() => import("./CustomRetro")),
  thisPurrfectHumanBelongsTo: React.lazy(() =>
    import("./ThisPurrfectHumanBelongsTo")
  ),
  customBleachPhoto: React.lazy(() => import("./CustomBleachStylePhoto")),
  customPhotoWithText: React.lazy(() => import("./CustomPhotoWithText")),
  customVintagePhoto: React.lazy(() => import("./CustomVintagePhoto")),
  customVintageDog: React.lazy(() => import("./CustomVintageDog")),
  customVintageCat: React.lazy(() => import("./CustomVintageCat")),
  customYearText: React.lazy(() => import("./CustomBirthYearSynthwave")),
  customCartoonStipesDog: React.lazy(() => import("./CustomCartoonStipesDog")),
  customPhotoBGWithText: React.lazy(() => import("./CustomPhotoBGWithText")),
  customRetroFont: React.lazy(() => import("./CustomRetroFont")),
  customGrungeFont: React.lazy(() => import("./CustomGrungeFont")),
  customCollagePhotos: React.lazy(() => import("./CustomCollagePhotos")),
  // customRetro: React.lazy(() => import("./CustomBootlegDesign")),
  // Add other design components here as needed
  // DesignTwo: React.lazy(() => import("./DesignTwo")),
};

const DesignLoader = ({
  designName,
  productBrand,
  canvasWidth,
  canvasHeight,
  canvasZoomRationDesktop,
  canvasZoomRationMobile,
  canvasZoomRationDesktopXL,
  canvasZoomRationTablet,
  ...props
}) => {
  const DesignComponent = designComponents[designName];
  return (
    <Suspense fallback={<div>Loading...</div>}>
      {DesignComponent ? (
        <DesignComponent
          {...props}
          productBrand={productBrand}
          canvasWidth={canvasWidth}
          canvasHeight={canvasHeight}
          canvasZoomRationDesktop={canvasZoomRationDesktop}
          canvasZoomRationMobile={canvasZoomRationMobile}
          canvasZoomRationDesktopXL={canvasZoomRationDesktopXL}
          canvasZoomRationTablet={canvasZoomRationTablet}
        />
      ) : (
        <div>Design not found</div>
      )}
    </Suspense>
  );
};

export default DesignLoader;
