import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Tooltip from "@mui/material/Tooltip";
import {
  CircularProgress,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { setProductType } from "../redux-slices/TemplateSlice";
import { useNavigate } from "react-router-dom";
import { setBasketProduct, setListing } from "../redux-slices/ProductSlice";
import CustomizeProductCanvas from "./CustomizeProductCanvas";
import { CanvasContext } from "../CanvasContext";
import CustomTextPopover from "./CustomTextPopover";
import CustomImageUploader from "./CustomImageUploader";

import { backgroundRemoverREMBGGoogle } from "../image-processing-tools";
import { setImgTemplateURL } from "../redux-slices/ImageSlice";
import ProductColorOptions from "./ProductColorOptions";
import { useParams } from "react-router-dom";
import { fabric } from "fabric";
import { setDesignFileURL } from "../redux-slices/ImageSlice";
import { Backdrop } from "@mui/material";
import DesignLoader from "./Designs/DesignLoader";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../theme";
import { Helmet } from "react-helmet";
import CustomProductPreview from "./CustomProductPreview";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  // padding: "5%",
  // p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  overflow: "auto",
};

export default function CustomizeProductComponent() {
  const { listingId, productBrand, designId } = useParams();

  const { fabricCanvasGlobal, productColorOption, productColor } =
    React.useContext(CanvasContext);
  // const downloadImageCanvas = useDesignCanvasDownloader(
  //   productCanvasRef.current
  // );
  // const downloadImageURL = useImageDownloader();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const imgTemplateURL = useSelector((state) => state.image.imgTemplateURL);
  const [closeButton, setCloseButton] = useState(false);
  const [confirmDesign, setConfirmDesign] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const matchesXS = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  // console.log("BREAKPOINT inside ViewMockup productType= " + productType);
  const [open, setOpen] = React.useState(true);
  const [product, setProduct] = useState(null);

  let canvasWidth,
    canvasHeight,
    canvasZoomRationDesktop,
    canvasZoomRationTablet,
    canvasZoomRationDesktopXL,
    previewRatio,
    canvasZoomRationMobile;
  if (productBrand === "Bella Canvas 3001") {
    canvasHeight = 5100;
    canvasWidth = 4500;
    canvasZoomRationDesktop = 0.4;
    canvasZoomRationMobile = 0.3;
    canvasZoomRationDesktopXL = 0.55;
    canvasZoomRationTablet = 0.2;
    previewRatio = 1.8;
  } else if (productBrand === "Gildan 18000") {
    canvasHeight = 5100;
    canvasWidth = 4500;
    canvasZoomRationDesktop = 0.4;
    canvasZoomRationDesktopXL = 0.55;
    canvasZoomRationMobile = 0.3;
    canvasZoomRationTablet = 0.2;
    previewRatio = 1.8;
  } else if (productBrand === "Gildan 18500") {
    canvasHeight = 3000;
    canvasWidth = 4500;
    canvasZoomRationDesktop = 0.3;
    canvasZoomRationDesktopXL = 0.36;
    canvasZoomRationMobile = 0.3;
    canvasZoomRationTablet = 0.2;
    previewRatio = 1.8;
  } else if (productBrand === "Bella Canvas 3480") {
    canvasHeight = 3600;
    canvasWidth = 3000;
    canvasZoomRationDesktop = 0.3;
    canvasZoomRationDesktopXL = 0.45;
    canvasZoomRationMobile = 0.2;
    canvasZoomRationTablet = 0.2;
    previewRatio = 1.8;
  }
  // const [text, setText] = useState("");
  // const [productDetails, setProductDetails] = useState([]);

  //take product listing from dynamodb
  // useEffect(() => {
  //   axios
  //     .get("http://localhost:8000/api/products/")
  //     .then((response) => {
  //       setProductDetails(response.data);
  //       console.log(
  //         "BREAKPOINT inside fetch dynamodb producst table= " +
  //           JSON.stringify(response.data)
  //       );
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching products:", error);
  //     });
  // }, []);

  // const updateProduct = (name, value) => {
  //   dispatch(setBasketProduct({ name, value }));
  // };
  // const updateListing = (name, value) => {
  //   dispatch(setListing({ name, value }));
  // };

  const handleRemoveBackground = () => {
    setLoading(true); // Set loading to true before starting the background removal
    backgroundRemoverREMBGGoogle(imgTemplateURL)
      .then((url) => {
        dispatch(setImgTemplateURL(url));
        setLoading(false); // Set loading to false after the operation is complete
        setError(false);
      })
      .catch((error) => {
        setError(true);
        // console.log("Error processing the image: " + error);
        setLoading(false); // Set loading to false if there is an error
      })
      .finally(() => {
        setLoading(false); // Stop the spinner
      });
  };

  // function downloadCanvasAsImage(fabricCanvas) {
  //   // Create a temporary canvas
  //   const tempCanvas = new fabric.StaticCanvas(null, {
  //     width: 4500,
  //     height: 5100,
  //     enableRetinaScaling: false,
  //   });
  //   const clonePromises = fabricCanvas.getObjects().map((obj) => {
  //     return new Promise((resolve) => {
  //       obj.clone((clone) => {
  //         // tempCanvas.add(clone);
  //         resolve(clone); // Resolve the promise with the clone
  //       });
  //     });
  //   });

  //   Promise.all(clonePromises).then((clonedObjects) => {
  //     // Instead of adding each clone in the clone callback,
  //     // we add them here in the correct order.
  //     clonedObjects.forEach((clone) => {
  //       tempCanvas.add(clone); // Add each clone to the tempCanvas
  //     });

  //     tempCanvas.renderAll(); // Render the tempCanvas after all clones are added
  //     // Further logic here, e.g., exporting the tempCanvas as an image
  //   });

  //   // Wait for all objects to clone before proceeding
  //   tempCanvas.renderAll();
  //   setTimeout(() => {
  //     // Export the temporary canvas to an image
  //     const dataURL = tempCanvas.toDataURL({
  //       format: "png",
  //       multiplier: 1, // Ensure it's exported at the canvas's original size
  //     });
  //     dispatch(setDesignFileURL(dataURL));
  //     // console.log("BREAKPOINT dataURL= " + dataURL);

  //     // Create a link to download the image
  //     // const link = document.createElement("a");
  //     // link.download = "design.png";
  //     // link.href = dataURL;
  //     // document.body.appendChild(link);
  //     // link.click();
  //     // document.body.removeChild(link);

  //     // Clean up the temporary canvas
  //     tempCanvas.dispose();
  //   }, 500); // Adjust timeout as necessary to ensure canvas has finished rendering
  // }

  function downloadCanvasAsImage(fabricCanvas) {
    return new Promise((resolve, reject) => {
      // Create a temporary canvas
      const tempCanvas = new fabric.StaticCanvas(null, {
        width: canvasWidth,
        height: canvasHeight,
        enableRetinaScaling: false,
      });

      const clonePromises = fabricCanvas.getObjects().map((obj) => {
        return new Promise((resolve) => {
          obj.clone((clone) => {
            resolve(clone);
          });
        });
      });

      Promise.all(clonePromises)
        .then((clonedObjects) => {
          clonedObjects.forEach((clone) => {
            tempCanvas.add(clone);
          });

          tempCanvas.renderAll();

          setTimeout(() => {
            const dataURL = tempCanvas.toDataURL({
              format: "png",
              multiplier: 1,
            });

            dispatch(setDesignFileURL(dataURL));

            tempCanvas.dispose();
            resolve(); // Resolve the promise after the operations are complete
          }, 500); // Adjust timeout as necessary to ensure canvas has finished rendering
        })
        .catch((error) => {
          // console.log("BREAKPOINT");
          reject(error); // Reject the promise if there is an error
        });
    });
  }

  // useEffect(() => {
  //   if (fabricCanvasGlobal && fabricCanvasGlobal.getContext())
  //     downloadCanvasAsImage(fabricCanvasGlobal);
  // }, [fabricCanvasGlobal]);

  // const handleDownloadButton = () => {
  //   downloadCanvasAsImage(fabricCanvasGlobal);
  // };

  const handleClose = () => {
    // downloadCanvasAsImage(fabricCanvasGlobal);
    setCloseButton(true);
    setOpen(false);
    setImgTemplateURL("");
    dispatch(setProductType("T-shirt"));
    navigate(-1);
  };

  // const handleConfirmDesignClick = () => {
  //   downloadCanvasAsImage(fabricCanvasGlobal);
  //   setConfirmDesign(true);
  //   setCloseButton(true);
  //   setOpen(false);
  //   navigate(-1);
  // };
  const handleConfirmDesignClick = async () => {
    // console.log("BREAKPOINT productColorOption = " + productColorOption);
    // console.log("BREAKPOINT productColor.img = " + productColor.img);
    if (productColorOption === productColor.img) {
      try {
        await downloadCanvasAsImage(fabricCanvasGlobal);
        setConfirmDesign(true);
        setCloseButton(true);
        setOpen(false);
        navigate(-1);
      } catch (error) {
        // console.error("Error downloading canvas as image:", error);
        // Handle the error if necessary
      }
    }
  };
  let colorOptions;
  if (matchesXS) {
    colorOptions = {
      overflow: "auto",
      width: "100%",
      zIndex: 2,
    };
  } else {
    colorOptions = {
      position: "absolute",
      left: "3%",
      top: "30%",
      overflow: "auto",
      height: "50%",
      zIndex: 2,
    };
  }

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Helmet>
          <title>Customize Your Product | Okay Artist</title>
          <meta
            name="description"
            content={`Design your custom ${productBrand} with ${designId} design at Okay Artist. Create your unique product with our design editor.`}
          />
          <meta
            name="keywords"
            content={`custom product, ${productBrand}, design editor, ${designId}, personalized clothing, okay artist`}
          />
          <meta
            property="og:title"
            content="Customize Your Product | Okay Artist"
          />
          <meta
            property="og:description"
            content={`Use Okay Artist to customize your ${productBrand} with the ${designId} design template. Perfect for creating a one-of-a-kind product.`}
          />
          <meta
            property="og:url"
            content="https://www.okayartist.com/customize"
          />
          <meta
            property="og:image"
            content="https://product-images-okayartist.s3.amazonaws.com/generic-images/logo-for-meta-tags.jpg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="Customize Your Product | Okay Artist"
          />
          <meta
            name="twitter:description"
            content={`Create and customize your own ${productBrand} with our ${designId} design template.`}
          />
          <meta
            name="twitter:image"
            content="https://product-images-okayartist.s3.amazonaws.com/generic-images/logo-for-meta-tags.jpg"
          />
        </Helmet>
        {/* <Button onClick={handleOpen}>Open modal</Button> */}
        {/* <Tooltip title="View Mockup" placement="bottom" arrow disableInteractive>
        <IconButton onClick={handleOpen} color="primary">
          <PreviewIcon />
        </IconButton>
      </Tooltip> */}
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {/* <DesignShirt1></DesignShirt1> */}
            {/* <FloatingToolbar></FloatingToolbar> */}
            <Stack direction={"row"} spacing={2} sx={{ mt: "5%" }}>
              <CustomImageUploader />
              <CustomTextPopover />
              <Button
                onClick={handleRemoveBackground}
                variant="outlined"
                disabled={loading}
                size="small"
              >
                Remove Background
              </Button>
              {error && <Typography>Please try again!</Typography>}
            </Stack>
            <Box
              sx={
                colorOptions
                // position: "absolute",
                // left: "3%",
                // top: "30%",
                // overflow: "auto",
                // height: "50%",

                // overflowX: "scroll",
                // width: "80%",
                // height: "auto",
              }
            >
              <ProductColorOptions
                listingId={listingId}
                productBrand={productBrand}
              />
            </Box>

            {/* <CustomBootlegDesign /> */}

            <DesignLoader
              designName={designId}
              productBrand={productBrand}
              canvasWidth={canvasWidth}
              canvasHeight={canvasHeight}
              canvasZoomRationDesktop={canvasZoomRationDesktop}
              canvasZoomRationMobile={canvasZoomRationMobile}
              canvasZoomRationDesktopXL={canvasZoomRationDesktopXL}
              canvasZoomRationTablet={canvasZoomRationTablet}
            ></DesignLoader>

            {/* <DesignLoader designName={"thisHumanBelongsTo"}></DesignLoader> */}
            {/* <CustomBootlegCloudsWithOnePhoto></CustomBootlegCloudsWithOnePhoto> */}
            {/* <CustomizeProductDesignTemplate text={"Okayartist"} /> */}
            <CustomizeProductCanvas
              closeButton={closeButton}
              confirmDesign={confirmDesign}
              setConfirmDesign={setConfirmDesign}
              previewRatio={previewRatio}
            />

            {/* <Box>
            <Typography>{text}</Typography>
            <TextField
              id="outlined-basic"
              label="Outlined"
              variant="outlined"
              value={text}
              onChange={handleTextChange}
            />
          </Box> */}
            <Stack direction={"column"} spacing={3} sx={{ mt: 2, mb: 2 }}>
              {product}
              <Stack direction={"row"} spacing={1} justifyContent={"end"}>
                <Button
                  variant="outlined"
                  onClick={handleConfirmDesignClick}
                  size="small"
                >
                  Confirm Design
                </Button>

                {/* <Button variant="text" onClick={handleDownloadButton}>
                <Tooltip title="Download Design">
                  <DownloadIcon />
                </Tooltip>
              </Button> */}
                <Button variant="text" onClick={handleClose}>
                  <Tooltip title="Close">
                    <CloseIcon />
                  </Tooltip>
                </Button>
              </Stack>
            </Stack>
            {/* <CustomProductPreview /> */}
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Box>
        </Modal>
      </Box>
    </ThemeProvider>
  );
}
